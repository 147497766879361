import React, {Component} from 'react'
import $ from 'jquery';

import CommentForm from './components/CommentForm'
import Comment from './components/Comment'
import LoadingIndicator from "./components/LoadingIndicator";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            isLoaded: false,
            comment_form: {
                comment_box: ''
            }
        };

        this.submit_comment = this.submit_comment.bind(this);
        this.comment_box_changed = this.comment_box_changed.bind(this);
    }

    componentDidMount() {
        $.ajax({
            url: process.env.API_URL + "comments",
            xhrFields: {
                withCredentials: true
            },
            type: "get",
            success: d => {
                if (d.code === 'ok') {
                    this.setState({
                        comments: d.result,
                        isLoaded: true
                    });
                }
            }
        });
    }

    comment_box_changed(e) {
        this.setState({
            comment_form: {
                comment_box: e.target.value
            }
        });
    }

    submit_comment(e) {
        let comment = this.state.comment_form.comment_box.trim();
        if (comment !== '') {
            $.ajax({
                url: process.env.API_URL + "comment",
                xhrFields: {
                    withCredentials: true
                },
                type: "post",
                data: {
                    comment: comment
                },
                success: d => {
                    if (d.code === 'ok') {
                        this.state.comment_form.comment_box = '';

                        let comments = this.state.comments;
                        comments.unshift(d.result);

                        this.setState({
                            comments: comments
                        });
                    }
                }
            });
        }

        e.preventDefault();
    }

    render() {
        return <div>
            {this.state.isLoaded ?
            <div>
                <CommentForm
                    comment_box={this.state.comment_form.comment_box}
                    comment_box_changed={this.comment_box_changed}
                    submit_comment={this.submit_comment}
                    show_comment_box={true}
                />
                <ul>
                    {this.state.comments.map(comment => (
                        <div key={comment.oid}>
                            <Comment comment={comment.comment} oid={comment.oid}/>
                        </div>
                    ))}
                </ul>
            </div> : <LoadingIndicator/>
            }
        </div>
    }
}

export default App
