import React, {Component} from 'react'

class LoadingIndicator extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <img className="loading-indicator" src="img/spinner.gif" alt=""/>
    }
}

export default LoadingIndicator
