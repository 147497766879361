import React, {Component} from 'react'

class CommentForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            {this.props.show_comment_box ?
            <form id="comment_form" onSubmit={this.props.submit_comment}>
                <textarea id="comment_textarea" name="comment_textarea" value={this.props.comment_box} onChange={this.props.comment_box_changed}/>
                <input type="submit" value={this.props.is_child_comment === undefined ? 'New Post' : 'Post'} />
            </form>
                : <div /> }
        </div>
    }
}

export default CommentForm
