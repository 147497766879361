import React, {Component} from 'react'

import CommentForm from "./CommentForm";
import $ from "jquery";
import LoadingIndicator from "./LoadingIndicator";

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            child_comments: [],
            isLoaded: false,
            comment_form: {
                comment_box: ''
            },
            show_comment_box: false
        };

        this.submit_comment = this.submit_comment.bind(this);
        this.comment_box_changed = this.comment_box_changed.bind(this);
        this.toggle_comment_box = this.toggle_comment_box.bind(this)
    }

    componentDidMount() {
        $.ajax({
            url: process.env.API_URL + "comment/" + this.props.oid,
            xhrFields: {
                withCredentials: true
            },
            type: "get",
            success: d => {
                if (d.code === 'ok') {
                    this.setState({
                        child_comments: d.result,
                        isLoaded: true
                    });
                }
            }
        });
    }

    comment_box_changed(e) {
        this.setState({
            comment_form: {
                comment_box: e.target.value
            }
        });
    }

    submit_comment(e) {
        let comment = this.state.comment_form.comment_box.trim();
        let parent_oid = this.props.oid.trim();

        if (comment !== '' && parent_oid !== '') {
            $.ajax({
                url: process.env.API_URL + "comment/" + parent_oid,
                xhrFields: {
                    withCredentials: true
                },
                type: "post",
                data: {
                    comment: comment
                },
                success: d => {
                    if (d.code === 'ok') {
                        this.state.comment_form.comment_box = '';

                        let child_comments = this.state.child_comments;
                        child_comments.unshift(d.result);

                        this.setState({
                            child_comments: child_comments,
                            isLoaded: true
                        });
                    }
                }
            });
        }

        e.preventDefault();
    }

    toggle_comment_box(e) {
        this.setState({
            show_comment_box: !this.state.show_comment_box
        });

        e.preventDefault();
    }

    render() {
        return <div>
            {this.state.isLoaded ?
            <div>
                <div>
                    <li>
                        {this.props.comment}
                        <a className="reply_link" onClick={this.toggle_comment_box} href="#">
                            {this.state.show_comment_box ? "Close" : "Reply..." }
                        </a>
                    </li>
                    <CommentForm
                        comment_box={this.state.comment_form.comment_box}
                        comment_box_changed={this.comment_box_changed}
                        submit_comment={this.submit_comment}
                        is_child_comment={true}
                        show_comment_box={this.state.show_comment_box}
                    />
                </div>
                <div className="children">
                    <ul>
                        {this.state.child_comments.map(child_comment => (
                            <div key={child_comment.oid}>
                                <Comment comment={child_comment.comment} oid={child_comment.oid}/>
                            </div>
                        ))}
                    </ul>
                </div>
            </div> : <LoadingIndicator/>
            }
        </div>
    }
}

export default Comment
